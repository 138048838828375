import React, { useState, useEffect } from 'react';
import './App.css';
import 'aos/dist/aos.css';
import MuiNavbar from './components/MuiNavbar';
import MuiCarousel from './components/MuiCarousel';
import Footer from './components/Footer';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Home from './pages/Home';
import Programs from './pages/Programs';
import Getinvolved from './pages/Getinvolved';
import Contact from './pages/Contact';
import About from './pages/About';
import Donate from './pages/Donate';
import ClipLoader from 'react-spinners/ClipLoader'; // A spinner component from react-spinners

// Spinner Component
function LoadingSpinner() {
  return (
    <div className="spinner-container">
      <ClipLoader color="#123abc" loading={true} size={100} />
    </div>
  );
}

// Main App Component
function App() {
  return (
    <Router>
      <MainContent />
    </Router>
  );
}

// Separate component to handle location change and loading spinner
function MainContent() {
  const [loading, setLoading] = useState(false);
  const location = useLocation(); // Now it will work inside the Router

  useEffect(() => {
    setLoading(true); // Start loading when route changes
    const timer = setTimeout(() => {
      setLoading(false); // Stop loading after delay (e.g., 2 seconds)
    }, 2000); // Set delay (in milliseconds)

    return () => clearTimeout(timer); // Clean up the timer when the component unmounts or route changes
  }, [location]); // Run effect whenever the location (route) changes

  return (
    <div>
      {loading ? ( // Show spinner if loading is true
        <LoadingSpinner />
      ) : (
        <>
          <MuiNavbar />
          <MuiCarousel />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/about-us" element={<About />} />
            <Route path="/programs" element={<Programs />} />
            <Route path="/get-involved" element={<Getinvolved />} />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="/donate" element={<Donate />} />
          </Routes>
          <Footer />
        </>
      )}
    </div>
  );
}

export default App;
