import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  InputBase,
  IconButton,
  ListItem,
  List,
  ListItemText,
  Drawer,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ArrowRightAlt, Search as SearchIcon } from "@mui/icons-material";
import { styled, alpha, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 0),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(0)})`,
    transition: theme.transitions.create("width"),
    width: "10%",
    height: "34px",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const MuiNavbar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [scrolling, setScrolling] = useState(false);
  const [imageHeight, setImageHeight] = useState(100);

  const handleScroll = () => {
    setScrolling(window.scrollY > 0);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const imgElement = document.getElementById("navbar-logo");
    if (imgElement) {
      setImageHeight(imgElement.height);
    }
  }, [scrolling]);

  const toggleDrawer = (open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setDrawerOpen(open);
  };

  return (
    <div>
      {/* Upper Navbar */}
      {!isMobile && (
        <AppBar
          position="fixed"
          style={{
            backgroundColor: "#b26500",
            top: scrolling ? "-64px" : "0px",
            transition: "top 0.3s",
            height: "50px",
          }}
        >
          <Toolbar
            style={{
              justifyContent: "flex-end",
              marginRight: "80px",
              minHeight: "50px",
            }}
          >
            <Typography variant="body1" style={{ marginRight: 16 }}>
              <Link to="#" style={{ textDecoration: "none", color: "white" }}>
                Login |
              </Link>
            </Typography>
            <Typography variant="body1" style={{ marginRight: 16 }}>
              <Link to="#" style={{ textDecoration: "none", color: "white" }}>
                Application |
              </Link>
            </Typography>
            <IconButton
              aria-label="facebook"
              href="https://www.facebook.com"
              target="_blank"
              rel="noopener"
              style={{ marginRight: 16 }}
            >
              <FacebookIcon />
            </IconButton>
            <IconButton
              aria-label="twitter"
              href="https://www.twitter.com"
              target="_blank"
              rel="noopener"
              style={{ marginRight: 16 }}
            >
              <TwitterIcon />
            </IconButton>
            <IconButton
              aria-label="instagram"
              href="https://www.instagram.com"
              target="_blank"
              rel="noopener"
            >
              <InstagramIcon />
            </IconButton>
            <Search>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
                style={{
                  backgroundColor: "white",
                  color: "black",
                }}
              />
            </Search>
            <div style={{ backgroundColor: "black", color: "white" }}>
              <IconButton style={{ width: "48px", height: "50px" }}>
                <SearchIcon style={{ color: "#fff" }} />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
      )}

      {/* Bottom Navbar */}
      {!isMobile ? (
        <AppBar
          position="fixed"
          style={{
            backgroundColor: scrolling ? "#123499" : "#fff",
            top: scrolling ? "0px" : "50px",
            transition: "all 0.3s",
            color: scrolling ? "#fff" : "#000",
            height: `${imageHeight}px`,
          }}
        >
          <Toolbar
            style={{
              justifyContent: "space-between",
              marginRight: "80px",
              marginLeft: "80px",
            }}
          >
            <img
              id="navbar-logo"
              src={
                scrolling
                  ? `${process.env.PUBLIC_URL}/Images/favicon.png`
                  : `${process.env.PUBLIC_URL}/Images/logo.png`
              }
              alt="Logo"
              height="80"
              onLoad={(e) => setImageHeight(e.target.height)}
            />
            <div style={{ color: scrolling ? "#fff" : "#000", paddingRight: "10px" }}>
              <Link to="/home" style={{ textDecoration: "none" }}>
                <Button color="inherit">Home</Button>
              </Link>{" "}
              |
              <Link to="/about-us" style={{ textDecoration: "none" }}>
                <Button color="inherit">About Us</Button>
              </Link>{" "}
              |
              <Link to="/programs" style={{ textDecoration: "none" }}>
                <Button color="inherit">Programs</Button>
              </Link>{" "}
              |
              <Link to="/get-involved" style={{ textDecoration: "none" }}>
                <Button color="inherit">Our Team</Button>
              </Link>{" "}
              |
              <Link to="/contact-us" style={{ textDecoration: "none" }}>
                <Button color="inherit">Contact Us</Button>
              </Link>
              <Link to="/donate" style={{ textDecoration: "none" }}>
                <Button
                  size="small"
                  variant="outlined"
                  style={{
                    borderColor: scrolling ? "#fff" : "#b26500",
                    color: scrolling ? "#fff" : "#000",
                    marginLeft: 8,
                    borderWidth: 7,
                    marginTop: 3,
                  }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.backgroundColor = "#b26500";
                    e.currentTarget.style.color = "#fff";
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.backgroundColor = "transparent";
                    e.currentTarget.style.color = scrolling ? "#fff" : "#123499";
                  }}
                >
                  Donate
                  <IconButton>
                    <ArrowRightAlt color="inherit" />
                  </IconButton>
                </Button>
              </Link>
            </div>
          </Toolbar>
        </AppBar>
      ) : (
        <>
          {/* Mobile Drawer Section */}
          <AppBar
            position="fixed"
            style={{
              backgroundColor: scrolling ? "#123499" : "#fff",
              top: scrolling ? "0px" : "0px",
              bottom: "40",
              transition: "all 0.3s",
              color: scrolling ? "#fff" : "#000",
              height: `${imageHeight}px`,
            }}
          >
            <Toolbar style={{ justifyContent: "space-between" }}>
              <img
                id="navbar-logo"
                src={
                  scrolling
                    ? `${process.env.PUBLIC_URL}/Images/favicon.png`
                    : `${process.env.PUBLIC_URL}/Images/logo.png`
                }
                alt="Logo"
                height="80"
                onLoad={(e) => setImageHeight(e.target.height)}
              />
              <div style={{ color: scrolling ? "#fff" : "#000" }}>
                <IconButton
                  color="inherit"
                  edge="end"
                  onClick={toggleDrawer(true)}
                  style={{ color: "black" }}
                >
                  <MenuIcon />
                </IconButton>
                <Link to="/donate" style={{ textDecoration: "none" }}>
                  <Button
                    size="small"
                    variant="outlined"
                    style={{
                      borderColor: "#b26500",
                      color: "black",
                      marginLeft: 8,
                      borderWidth: 2,
                      marginTop: 3,
                    }}
                  >
                    Donate
                    <IconButton>
                      <ArrowRightAlt />
                    </IconButton>
                  </Button>
                </Link>
              </div>
            </Toolbar>
          </AppBar>

          <Drawer
            anchor="right"
            open={drawerOpen}
            onClose={toggleDrawer(false)}
            PaperProps={{ style: { width: "200px" } }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "16px",
              }}
            >
              <IconButton onClick={toggleDrawer(false)}>
                <CloseIcon />
              </IconButton>
            </div>
            <List>
              <ListItem button component={Link} to="/home">
                <ListItemText primary="Home" />
              </ListItem>
              <ListItem button component={Link} to="/about-us">
                <ListItemText primary="About Us" />
              </ListItem>
              <ListItem button component={Link} to="/programs">
                <ListItemText primary="Programs" />
              </ListItem>
              <ListItem button component={Link} to="/get-involved">
                <ListItemText primary="Our Team" />
              </ListItem>
              <ListItem button component={Link} to="/contact-us">
                <ListItemText primary="Contact Us" />
              </ListItem>
              <ListItem button component={Link} to="/donate">
                <ListItemText primary="Donate" />
              </ListItem>
            </List>
          </Drawer>
        </>
      )}
    </div>
  );
};

export default MuiNavbar;
