import React from 'react'

export default function Facts() {
  return (
    <div>
        <section className="facts section-bg">
        <div className="container">
          <div className="row counters">
            <div className="col-lg-3 col-6 text-center">
              <span className="purecounter">232</span>
              <p>Scholarships awarded</p>
            </div>
            <div className="col-lg-3 col-6 text-center">
              <span className="purecounter">521</span>
              <p>Total funds raised</p>
            </div>
            <div className="col-lg-3 col-6 text-center">
              <span className="purecounter">1463</span>
              <p>Communities impacted</p>
            </div>
            <div className="col-lg-3 col-6 text-center">
              <span className="purecounter">15</span>
              <p>Youth trained</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
