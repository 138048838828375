import { useState } from "react";

function About() {
  const [showAll, setShowAll] = useState(false);

  const achievementsData = [
    {
      title: "Managing Kongowe Primary School",
      description:
        "Overseeing daily operations and enhancing educational standards.",
      icon: "🏫",
    },
    {
      title: "Advisory Manager Othman Ibn Affan Primary School",
      description: "Providing strategic advice for academic excellence.",
      icon: "📘",
    },
    {
      title: "Management Advisory ACME Pre and Primary School",
      description: "Supporting school management and administration.",
      icon: "👨‍🏫",
    },
    {
      title: "A Level Students Sponsorship",
      description: "Sponsoring students for their A-level studies.",
      icon: "🎓",
    },
    {
      title: "Youth Skills Development Training",
      description: "Trained over 500 youths in various vocational skills.",
      icon: "🛠️",
    },
    {
      title: "Food distribution to the needy",
      description: "Organized food drives for underprivileged communities.",
      icon: "🍲",
    },
    {
      title: "Training Schools on Financial Management",
      description:
        "Educating schools on effective financial management practices.",
      icon: "💼",
    },
    {
      title: "Books distribution to cultural centers",
      description: "Donated over 1,000 books to promote education.",
      icon: "📚",
    },
    {
      title: "Customer Service Management Training",
      description:
        "Training integrated schools on customer service management.",
      icon: "👥",
    },
    {
      title: "Construction and renovations of social and public gathering",
      description:
        "Involved in construction and renovation projects for social services.",
      icon: "🏗️",
    },
  ];

  const achievementsToDisplay = showAll ? achievementsData : achievementsData.slice(0, 5);

  return (
    <>
      <main id="main">
        <section className="about">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <img
                  src="./images/about.png"
                  className="img-fluid"
                  alt="About Us"
                />
              </div>
              <div className="col-lg-6 pt-4 pt-lg-0">
                <h3>About Pamoja Foundation</h3>
                <p>
                  Pamoja Foundation is a non-profit organization working to
                  improve the socioeconomic well-being of Tanzanians. The
                  Foundation started its operations in 2011 and was officially
                  registered in Tanzania in 2014 by the Business Registration
                  and Licensing Agency as a Company Limited by Guarantee without
                  share capital, with registration number 106554.
                </p>
                <ul>
                  <li>
                    <i className="bi bi-check2-circle" /> The Foundation is
                    committed to addressing issues that affect the well-being of
                    Tanzanians.
                  </li>
                  <li>
                    <i className="bi bi-check2-circle" /> Through various
                    initiatives, the Foundation aims to create sustainable
                    solutions that enhance the socio-economic status of
                    communities.
                  </li>
                  <li>
                    <i className="bi bi-check2-circle" /> Since its inception,
                    Pamoja Foundation has partnered with local and international
                    stakeholders to implement programs that have a lasting
                    impact.
                  </li>
                </ul>
                <p>
                  The Foundation’s efforts focus on areas such as education,
                  healthcare, and economic empowerment. By working closely with
                  communities and utilizing available resources, Pamoja
                  Foundation continues to play a vital role in improving the
                  lives of many Tanzanians.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="vision-mission">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 vision">
                <h2 className="header">Our Vision</h2>
                <div className="content-box">
                  <div className="icon">🌟</div>
                  <p className="text">
                    Talented students and youth are empowered for Social
                    Transformation.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 mission">
                <h2 className="header">Our Mission</h2>
                <div className="content-box">
                  <div className="icon">🌱</div>
                  <p className="text">
                    To promote talented students and youth in Tanzania to become
                    contributing citizens through sponsorship, scholarships,
                    trainings, and job creation.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="values">
          <div className="">
            <h1>CORE VALUES</h1>
          </div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch">
                <div className="icon-box icon-box-pink">
                  <div className="icon">
                    <i className="bx bx-shield"></i>
                  </div>
                  <h4 className="title">Integrity</h4>
                  <p className="description">
                    We shall live and work with high integrity and moral
                    standards all the time and at everyone’s best ability. This
                    implies, being sincere, keeping promises, truthful, and
                    trustworthy to our Lord, other human beings, and ourselves.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch">
                <div className="icon-box icon-box-cyan">
                  <div className="icon">
                    <i className="bx bx-group"></i>
                  </div>
                  <h4 className="title">Togetherness</h4>
                  <p className="description">
                    We encourage and promote teamwork to our internal players
                    and collaboration on a win-win approach with our partners
                    and stakeholders. We emphasize and celebrate the success of
                    all in our team. No one should be left behind despite the
                    challenges he or she faces. We shall be together during good
                    and tough times.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch">
                <div className="icon-box icon-box-green">
                  <div className="icon">
                    <i className="bx bx-check-shield"></i>
                  </div>
                  <h4 className="title">Accountability</h4>
                  <p className="description">
                    We shall be accountable for whatever we do, and everyone is
                    responsible for the outcomes of their actions. We discourage
                    complaining, blaming, and finger-pointing when things go
                    wrong or results are not impressive.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch">
                <div className="icon-box icon-box-blue">
                  <div className="icon">
                    <i className="bx bx-star"></i>
                  </div>
                  <h4 className="title">Excellence</h4>
                  <p className="description">
                    We shall demonstrate high performance standards in all
                    undertakings, including work assignments, studies, and
                    relationships.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch">
                <div className="icon-box icon-box-orange">
                  <div className="icon">
                    <i className="bx bx-heart"></i>
                  </div>
                  <h4 className="title">Service</h4>
                  <p className="description">
                    We shall serve the community with humility wherever we are,
                    with whatever good we are blessed with, including talent,
                    time, and money. Being a member of Pamoja means being a
                    servant of the people and communities.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="achievements">
      <div>
        <h1>ACHIEVEMENTS</h1>
      </div>
      <div className="achievements-container">
        <div className="achievements-grid">
          {achievementsToDisplay.map((achievement, index) => (
            <div className="achievement-card" key={index}>
              <div className="achievement-icon">
                <span>{achievement.icon}</span>
              </div>
              <h3 className="achievement-title">{achievement.title}</h3>
              <p className="achievement-description">
                {achievement.description}
              </p>
            </div>
          ))}
        </div>
        {/* Read More/Hide Button */}
        <div className="read-more text-right">
  {showAll ? (
    <button 
      className="btn btn-primary" 
      onClick={() => setShowAll(false)}
    >
      Hide Achievements
    </button>
  ) : (
    <button 
      className="btn btn-primary" 
      onClick={() => setShowAll(true)}
    >
      View All
    </button>
  )}
</div>

      </div>
    </section>
      </main>
    </>
  );
}

export default About;
