import React, { useState } from "react";

export default function DonateTabs() {
  const [activeTab, setActiveTab] = useState("mobile");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="donate">
      <h2>Donate to Pamoja Foundation</h2>
      <div className="tabs me-4 ms-4">
        <button
          className={`tab-button ${activeTab === "mobile" ? "active" : ""}`}
          onClick={() => handleTabClick("mobile")}
        >
          Contribute Through Mobile
        </button>
        <button
          className={`tab-button ${activeTab === "bank" ? "active" : ""}`}
          onClick={() => handleTabClick("bank")}
        >
          Contribute Through Bank
        </button>
      </div>

      <div className="tab-content">
        {activeTab === "mobile" && (
          <div className="content">
            <h3>Contribute Through Mobile</h3>
            <p>
              Get Involved by contributing through T-PESA
              <br />
              <span className="blue-text"> 0734313967</span>
            </p>
          </div>
        )}
        {activeTab === "bank" && (
          <div className="content">
            <h3>Contribute Through Bank</h3>
            <p>
              Get Involved by contributing through Amana Bank
              <br />
              <span className="blue-text"> 002140390990001</span>
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
