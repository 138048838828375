import React from 'react'

export default function EventsAnnouncement() {
  return (
    <div>
      <main>
        <div className="section-titletwo">
          <h1>Upcoming Initiatives & News</h1>
        </div>
        <section className="pricing section-bg">
          <div className="container">
            <div className="section-title">
              <h2>Events and Announcements</h2>
              <p>
                Stay tuned for our latest updates and exciting events! We are thrilled to announce our upcoming fundraising gala on December 15th, which aims to support educational programs for Tanzanian youth. In addition, we will be inaugurating a new vocational training center in Dodoma next month, focused on skill enhancement and job creation. We're also partnering with TechForGood to roll out essential digital skills training. Your support helps us make a significant impact!
              </p>
            </div>

            <div className="row no-gutters">
              <div className="col-lg-4 box">
                <h3>Upcoming Fundraising Events</h3>
                <ul>
                  <li>
                    <i className="bx bx-check"></i> Annual Charity Gala
                  </li>
                  <li>
                    <i className="bx bx-check"></i> Youth Empowerment Workshop
                  </li>
                  <li>
                    <i className="bx bx-check"></i> Community Health Fair
                  </li>
                  <li>
                    <i className="bx bx-check"></i> Technology Skills Bootcamp
                  </li>
                  <li>
                    <i className="bx bx-check"></i> Art and Culture Exhibition
                  </li>
                </ul>
                <a href="#" className="get-started-btn">
                  Read More
                </a>
              </div>

              <div className="col-lg-4 box">
                <h3>New Project Launches</h3>
                <ul>
                  <li>
                    <i className="bx bx-check"></i> Dodoma Vocational Training Center
                  </li>
                  <li>
                    <i className="bx bx-check"></i> Digital Literacy Program
                  </li>
                  <li>
                    <i className="bx bx-check"></i> Rural Education Expansion
                  </li>
                  <li>
                    <i className="bx bx-check"></i> Community Health Outreach
                  </li>
                  <li>
                    <i className="bx bx-check"></i> Environmental Awareness Campaign
                  </li>
                </ul>
                <a href="#" className="get-started-btn">
                  Read More
                </a>
              </div>

              <div className="col-lg-4 box">
                <h3>Recent Partnerships or Collaborations</h3>
                <ul>
                  <li>
                    <i className="bx bx-check"></i> Collaboration with TechForGood
                  </li>
                  <li>
                    <i className="bx bx-check"></i> Partnership with Local Schools
                  </li>
                  <li>
                    <i className="bx bx-check"></i> Alliance with Health NGOs
                  </li>
                  <li>
                    <i className="bx bx-check"></i> Support from Corporate Sponsors
                  </li>
                  <li>
                    <i className="bx bx-check"></i> Collaboration with Government Agencies
                  </li>
                </ul>
                <a href="#" className="get-started-btn">
                  Read More
                </a>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  )
}
