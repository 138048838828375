import React from 'react';
import { Link } from "react-router-dom";
import { Box, Typography, Button, Card, CardContent } from '@mui/material';
import { useTheme, useMediaQuery } from '@mui/material';

const OverlayCard = () => {
  const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  return (
    
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '500px',
        backgroundImage: `url(${process.env.PUBLIC_URL}/Images/pamoja.jpg)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        marginTop: isSmallScreen ? '80px' : '130px', // Adjust padding for small and large screens
        marginBottom: isSmallScreen ? '200px' : '130px',
      }}
    >
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          maxWidth: '1200px',
          height: 'auto', // Let the card height adjust based on content
          color: 'white',
          borderRadius: 2,
          boxShadow: 3,
          p: 4,
          position: 'absolute',
          bottom: '-120px',
          left: '50%',
          transform: 'translateX(-50%)',
          '@media (max-width: 600px)': {
            flexDirection: 'column', // Stack contents vertically on small screens
            bottom: '-200px', // Adjust position for smaller screens
             
          },
        }}
      >
        <CardContent
          sx={{
            flex: 1,
            bgcolor: 'white',
            color: 'black',
            p: 2,
            borderRadius: 1,
            mb: { xs: 2, sm: 0 }, // Add margin bottom on small screens
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',  // Center content horizontally
            justifyContent: 'center', // Center content vertically
            textAlign: 'center', // Center text
          }}
        >
          <Typography variant="h5" component="div" sx={{ fontWeight: 'bold', mb: 1 }}>
          PAMOJA FOUNDATION<br />
          Empowering Tanzanian youth through education, training, and support.
          </Typography>
        </CardContent>
        <CardContent
          sx={{
            flex: 1,
            borderLeft: { xs: 'none', sm: '1px solid #fff' }, // Remove border on small screens
            pl: { xs: 0, sm: 2 }, // Adjust padding left on small screens
            bgcolor: '#1a1a33',
            color: 'white',
            p: 2,
            borderRadius: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',  // Center content horizontally
            justifyContent: 'center', // Center content vertically
            textAlign: 'center', // Center text
          }}
        >
          <Typography variant="body1" component="div" sx={{ mb: 1 }}>
            We work on projects related to education, health, and economic development, aiming to improve the quality of life and promote sustainable development in the region.
          </Typography>
          <Link to="/programs" style={{ textDecoration: 'none' }}>
          <Button
            variant="outlined"
            sx={{
              borderColor: '#ff9933',
              borderWidth: 6,
              color: '#ff9933',
              mt: 2,
              '&:hover': {
                bgcolor: '#ff9933',
                color: 'white',
              },
            }}
          >
            MORE ABOUT OUR PROGRAMS →
          </Button>
        </Link>
        </CardContent>
      </Card>
    </Box>
  );
};

export default OverlayCard;
