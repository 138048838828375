import React from 'react'

function Getinvolved() {
  return (
    <>
    <section class="team">
    <div class="">
<h1>OUR LEADERS</h1>
</div>
<div class="container">
<div class="row">
<div class="col-lg-4 col-md-6 d-flex align-items-stretch">
  <div class="member">
    <div class="member-img">
      <img
        src="assets/img/team/team-1.jpg"
        class="img-fluid"
        alt=""
      ></img>
      <div class="social">
        <a href="">
          <i class="bi bi-twitter"></i>
        </a>
        <a href="">
          <i class="bi bi-facebook"></i>
        </a>
        <a href="">
          <i class="bi bi-instagram"></i>
        </a>
        <a href="">
          <i class="bi bi-linkedin"></i>
        </a>
      </div>
    </div>
    <div class="member-info">
      <h4>Walter White</h4>
      <span>Chief Executive Officer</span>
      <p>
        Animi est delectus alias quam repellendus nihil nobis
        dolor. Est sapiente occaecati et dolore. Omnis aut ut
        nesciunt explicabo qui. Eius nam deleniti ut omnis
        repudiandae perferendis qui. Neque non quidem sit sed
        pariatur quia modi ea occaecati. Incidunt ea non est
        corporis in.
      </p>
    </div>
  </div>
</div>

<div class="col-lg-4 col-md-6 d-flex align-items-stretch">
  <div class="member">
    <div class="member-img">
      <img
        src="assets/img/team/team-2.jpg"
        class="img-fluid"
        alt=""
      ></img>
      <div class="social">
        <a href="">
          <i class="bi bi-twitter"></i>
        </a>
        <a href="">
          <i class="bi bi-facebook"></i>
        </a>
        <a href="">
          <i class="bi bi-instagram"></i>
        </a>
        <a href="">
          <i class="bi bi-linkedin"></i>
        </a>
      </div>
    </div>
    <div class="member-info">
      <h4>Sarah Jhonson</h4>
      <span>Product Manager</span>
      <p>
        Aspernatur iste esse aliquam enim et corporis. Molestiae
        voluptatem aut eligendi quis aut. Libero vel amet
        voluptatem eos rerum non doloremque. Dolores eum non.
      </p>
    </div>
  </div>
</div>

<div class="col-lg-4 col-md-6 d-flex align-items-stretch">
  <div class="member">
    <div class="member-img">
      <img
        src="assets/img/team/team-3.jpg"
        class="img-fluid"
        alt=""
      ></img>
      <div class="social">
        <a href="">
          <i class="bi bi-twitter"></i>
        </a>
        <a href="">
          <i class="bi bi-facebook"></i>
        </a>
        <a href="">
          <i class="bi bi-instagram"></i>
        </a>
        <a href="">
          <i class="bi bi-linkedin"></i>
        </a>
      </div>
    </div>
    <div class="member-info">
      <h4>William Anderson</h4>
      <span>CTO</span>
      <p>
        Ut enim possimus nihil cupiditate beatae. Veniam facere
        quae non qui necessitatibus rerum eos vero. Maxime sit
        sunt quo dolor autem est qui quaerat aliquid. Tenetur
        possimus qui enim.
      </p>
    </div>
  </div>
</div>

<div class="col-lg-4 col-md-6 d-flex align-items-stretch">
  <div class="member">
    <div class="member-img">
      <img
        src="assets/img/team/team-4.jpg"
        class="img-fluid"
        alt=""
      ></img>
      <div class="social">
        <a href="">
          <i class="bi bi-twitter"></i>
        </a>
        <a href="">
          <i class="bi bi-facebook"></i>
        </a>
        <a href="">
          <i class="bi bi-instagram"></i>
        </a>
        <a href="">
          <i class="bi bi-linkedin"></i>
        </a>
      </div>
    </div>
    <div class="member-info">
      <h4>Amanda Jepson</h4>
      <span>Accountant</span>
      <p>
        Sint qui cupiditate. Asperiores fugit impedit aspernatur
        et mollitia. Molestiae qui placeat labore assumenda id
        qui nesciunt quo reprehenderit. Rem dolores similique
        quis soluta culpa enim quia ratione ea.
      </p>
    </div>
  </div>
</div>

<div class="col-lg-4 col-md-6 d-flex align-items-stretch">
  <div class="member">
    <div class="member-img">
      <img
        src="assets/img/team/team-5.jpg"
        class="img-fluid"
        alt=""
      ></img>
      <div class="social">
        <a href="">
          <i class="bi bi-twitter"></i>
        </a>
        <a href="">
          <i class="bi bi-facebook"></i>
        </a>
        <a href="">
          <i class="bi bi-instagram"></i>
        </a>
        <a href="">
          <i class="bi bi-linkedin"></i>
        </a>
      </div>
    </div>
    <div class="member-info">
      <h4>Niall Katz</h4>
      <span>Marketing</span>
      <p>
        Aut ex esse explicabo quia harum ea accusamus excepturi.
        Temporibus at quia quisquam veritatis impedit. Porro
        laborum voluptatum sed necessitatibus a saepe. Deserunt
        laborum quasi consequatur voluptatum iusto sint qui fuga
        vel. Enim eveniet sed quibusdam rerum in. Non dicta
        architecto consequatur quo praesentium nesciunt.
      </p>
    </div>
  </div>
</div>

<div class="col-lg-4 col-md-6 d-flex align-items-stretch">
  <div class="member">
    <div class="member-img">
      <img
        src="assets/img/team/team-6.jpg"
        class="img-fluid"
        alt=""
      ></img>
      <div class="social">
        <a href="">
          <i class="bi bi-twitter"></i>
        </a>
        <a href="">
          <i class="bi bi-facebook"></i>
        </a>
        <a href="">
          <i class="bi bi-instagram"></i>
        </a>
        <a href="">
          <i class="bi bi-linkedin"></i>
        </a>
      </div>
    </div>
    <div class="member-info">
      <h4>Demi Lewis</h4>
      <span>Financing</span>
      <p>
        Amet labore numquam corrupti est. Nostrum amet voluptas
        consectetur dolor voluptatem architecto distinctio
        consequuntur eligendi. Quam impedit enim aut nesciunt
        aut dicta quam exercitationem. Reprehenderit
        exercitationem magnam. Ullam similique ut voluptas totam
        nobis porro accusamus nulla omnis.
      </p>
    </div>
  </div>
</div>
</div>
</div>
</section>
</>
  )
}

export default Getinvolved