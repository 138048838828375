import React from "react";

export default function CardComponent() {
  return (
    <div>
    <main>
    <div class="section-titleone">
              <h1>PROGRAMS</h1>
            </div>
      <section class="service-details">
        <div class="container">
          <div class="row">
            <div class="col-md-6 d-flex align-items-stretch">
              <div class="card">
                <div class="card-img">
                  <img src="assets/img/service-details-1.jpg" alt="..."></img>
                </div>
                <div class="card-body">
                  <h5 class="card-title">
                    <a href="#">EDUCATION</a>
                  </h5>
                  <p class="card-text">
                    Lorem ipsum dolor sit amet, consectetur elit, sed do eiusmod
                    tempor ut labore et dolore magna aliqua. Ut enim ad minim
                    veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat
                  </p>
                  <div class="read-more">
                    <a href="#">
                      <i class="bi bi-arrow-right"></i> Read More
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 d-flex align-items-stretch">
              <div class="card">
                <div class="card-img">
                  <img src="assets/img/service-details-2.jpg" alt="..."></img>
                </div>
                <div class="card-body">
                  <h5 class="card-title">
                    <a href="#">HEALTH</a>
                  </h5>
                  <p class="card-text">
                    Sed ut perspiciatis unde omnis iste natus error sit
                    voluptatem doloremque laudantium, totam rem aperiam, eaque
                    ipsa quae ab illo inventore veritatis et quasi architecto
                    beatae vitae dicta sunt explicabo
                  </p>
                  <div class="read-more">
                    <a href="#">
                      <i class="bi bi-arrow-right"></i> Read More
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 d-flex align-items-stretch">
              <div class="card">
                <div class="card-img">
                  <img src="assets/img/service-details-3.jpg" alt="..."></img>
                </div>
                <div class="card-body">
                  <h5 class="card-title">
                    <a href="#">ECONOMIC AND COMMUNITY DEVELOPMENT</a>
                  </h5>
                  <p class="card-text">
                    Nemo enim ipsam voluptatem quia voluptas sit aut odit aut
                    fugit, sed quia magni dolores eos qui ratione voluptatem
                    sequi nesciunt Neque porro quisquam est, qui dolorem ipsum
                    quia dolor sit amet
                  </p>
                  <div class="read-more">
                    <a href="#">
                      <i class="bi bi-arrow-right"></i> Read More
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 d-flex align-items-stretch">
              <div class="card">
                <div class="card-img">
                  <img src="assets/img/service-details-4.jpg" alt="..."></img>
                </div>
                <div class="card-body">
                  <h5 class="card-title">
                    <a href="#">YOUTH EMPOWEREMENT</a>
                  </h5>
                  <p class="card-text">
                    Nostrum eum sed et autem dolorum perspiciatis. Magni porro
                    quisquam laudantium voluptatem. In molestiae earum ab sit
                    esse voluptatem. Eos ipsam cumque ipsum officiis qui nihil
                    aut incidunt aut
                  </p>
                  <div class="read-more">
                    <a href="#">
                      <i class="bi bi-arrow-right"></i> Read More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      </main>
    </div>
  );
}
