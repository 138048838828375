import React from 'react';
import CardComponent from '../components/CardComponent';
import Facts from '../components/Facts';
import EventsAnnouncement from '../components/EventsAnnouncement';

const Home = () => {
  return (
    <>
      <CardComponent />
      <Facts />
      <EventsAnnouncement />
    </>
  );
};

export default Home;
